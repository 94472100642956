export type MySQLDate = `${number}-${number}-${number} ${number}:${number}:${number}`;

export const getClientDate = (date: Date): string => {
    return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())} ${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}:${zeroPad(date.getSeconds())}`;
}

const zeroPad = (num: number): string => {
    return num < 10 ? `0${num}` : num.toString();
}

// copied from backend/src/util/Date.ts
export const isThePreviousDay = (date: MySQLDate, referenceDate: MySQLDate): boolean => {
    const previousDay = new Date(referenceDate);
    previousDay.setDate(new Date(referenceDate).getDate() - 1);
    return new Date(date).toDateString() === previousDay.toDateString();
}

// copied from backend/src/util/Date.ts
export const isTheSameDay = (date1: MySQLDate, date2: MySQLDate): boolean => {
    return new Date(date1).toDateString() === new Date(date2).toDateString();
}

// copied from backend/src/util/Date.ts
export const convertJSDateObjectToMySQLDateString = (date: Date): MySQLDate | undefined => {
    let dateStr: string;
    try {
        dateStr = date.toISOString().slice(0, 19).replace('T', ' ');
    } catch (error) {
        return undefined;
    }
    if (!isValidMysqlDateString(dateStr)) {
        return undefined;
    } else {
        return dateStr as MySQLDate;
    }
}

// copied from backend/src/util/Date.ts
export const isValidMysqlDateString = (dateStr: string): boolean => {
    return /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(dateStr)
}
