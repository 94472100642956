import { ILexemePair } from '../design/ILexemePair';
import { ApiMethod, ApiPath } from '../design/Path';
import { Familiarity } from '../enum/Familiarity';
import { Language } from '../enum/Language';
import { TestType } from '../enum/TestType';
import LexemeCollectionParser from '../parsers/LexemeCollectionParser';
import LexemePairCollectionParser from '../parsers/LexemePairCollectionParser';
import LexemePairParser from '../parsers/LexemePairParser';
import { convertJSDateObjectToMySQLDateString, MySQLDate } from './Date';
import LoaderUtil from './LoaderUtil';
import { Logger } from './Logger';
import { ILexeme } from '../valueobject/LexemeBase';
import { LexemePairPayload } from '../valueobject/LexemePairPayload';

export enum ApiError {
    GET_LEXEMES = 'GET_LEXEMES',
    GET_LEXEMES_FOR_TEST = 'GET_LEXEMES_FOR_TEST',
    GET_LEXEME_PAIR_BY_IDS = 'GET_LEXEME_PAIR_BY_IDS',
    GET_LEXEME_PAIRS_BY_TEXT = 'GET_LEXEME_PAIRS_BY_TEXT',
    GET_ALL_TRANSLATIONS = 'GET_ALL_TRANSLATIONS',
    DELETE_LEXEME_PAIR = 'DELETE_LEXEME_PAIR',
    INVALID_LEXEME_PAIR = 'INVALID_LEXEME_PAIR',
    PUT_LEXEME_PAIR_FAMILIARITY = 'PUT_LEXEME_PAIR_FAMILIARITY',
    POST_LEXEME_PAIR = 'POST_LEXEME_PAIR',
    POST_TRANSLATION = 'POST_TRANSLATION',
    TEST_COMPLETE = 'TEST_COMPLETE',
}

export enum UIError {
    INVALID_LEXEME_PAIR = 'INVALID_LEXEME_PAIR',
}

export enum ParseError {
    LEXEMES = 'LEXEMES',
    LEXEME_CZECH = 'LEXEME_CZECH',
    LEXEME_ENGLISH = 'LEXEME_ENGLISH',
    LEXEME_PAIRS = 'LEXEME_PAIRS',
    LEXEME_PAIR = 'LEXEME_PAIR',
    DELETE_LEXEME_PAIR = 'DELETE_LEXEME_PAIR',
    PUT_LEXEME_PAIR_FAMILIARITY = 'PUT_LEXEME_PAIR_FAMILIARITY',
    POST_LEXEME_PAIR = 'POST_LEXEME_PAIR',
}

export enum RequestError {
    TIMEOUT = 'TIMEOUT',
    UNHANDLED = 'UNHANDLED',
    UNAUTHORIZED = 'UNAUTHORIZED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type ResponseError = {
    status: number;
    statusText: string;
    message?: string;
}

export type AppError = ApiError | ParseError | RequestError | ResponseError;
export type Result<T> = [T, undefined] | [undefined, AppError]
export type ResultLexemePairs = Result<Array<ILexemePair>>;
export type ResultLexemePair = Result<ILexemePair>;
export type ResultLexeme<T extends ILexeme> = Result<T>;
export type ResultLexemes<T extends ILexeme> = Result<Array<T>>;
export type ResultString = Result<string>;
export type ResultVoid = Result<void>;
export type ResultJson = Result<any>;

export type UserData = {
    googleSub: string,
    name: string,
    email: string,
    phone: string,
    signUpDate: string,
    lastModified: string,
    learningLanguage: Language,
    uiLanguage: Language,
    testsCompletedCount: number,
    currentStreakLength: number,
    currentStreakEnd: MySQLDate,
    highStreakLength: number,
    highStreakEnd: MySQLDate,
}

export const devModeUserData: Partial<UserData> = {
    googleSub: "dev-mode-user",
    learningLanguage: Language.CZECH,
    uiLanguage: Language.ENGLISH,
    currentStreakEnd: convertJSDateObjectToMySQLDateString(new Date()),
    currentStreakLength: 45,
    highStreakEnd: "2021-01-01 00:00:00",
    highStreakLength: 199
}

export type StreakData = Pick<
    UserData,
    "currentStreakLength" |
    "currentStreakEnd" |
    "highStreakLength" |
    "highStreakEnd" |
    "testsCompletedCount"
>;

export type DeeplTranslation = {
    detectedSourceLanguage: string,
    translations: Array<{
        translatedText: string
    }>
}

/**
 * This class is a facade for the backend API.
 * It is responsible for making requests to the backend and parsing the responses.
 * It is also responsible for handling errors and logging exceptions.
 */
export class Api {

    static autologin = async (): Promise<UserData | undefined> => {
        const path = ApiPath.AUTOLOGIN
        const method = ApiMethod.GET;
        const [response, error] = await LoaderUtil.request<{user:UserData}>(path, method);
        if (error) return undefined;
        return response.user;
    }

    static signIn = async (rememberMe: boolean): Promise<UserData | undefined> => {
        const path = ApiPath.SIGN_IN;
        const method = ApiMethod.POST;
        const [response, error] = await LoaderUtil.request<{user:UserData}>(path, method, { rememberMe });
        if (error) return undefined;
        return response.user;
    }

    static signOut = async (): Promise<boolean> => {
        const path = ApiPath.SIGN_OUT;
        const method = ApiMethod.GET;
        const [, error] = await LoaderUtil.request<any>(path, method);
        return !error;
    }

    static callApiForMysqlResponse = async<T>(
        path: string,
        method: ApiMethod,
        apiError: ApiError,
        parser: (data: any) => Result<T>,
        parseError: ParseError,
        body?: any,
    ): Promise<Result<T>> => {
        const [result, requestError] = await LoaderUtil.request<T[]>(path, method, body);
        if (requestError) {
            Logger.exception(new Error(`[Api.callApiForMysqlResponse ${apiError}]: ${requestError}`));
            return [undefined, apiError];
        }
        const [parsedResult, parsingError] = parser(result);
        if (parsingError) {
            Logger.exception(new Error(`[Api.callApiForMysqlResponse ${parseError}]: ${parsingError}`));
            return [undefined, parseError];
        }
        // TODO - remove the unsafe cast below - this is a temporary solution to handle the case where adding a duplicate
        // lexeme pair results in a 200 status code with a copy of the mapping record for the pre-existing pair.
        return [parsedResult ?? result as T, undefined];
    }

    static getLexemes = async (page: number, length: number): Promise<ResultLexemePairs> => {
        return await Api.callApiForMysqlResponse<ILexemePair[]>(
            `${ApiPath.LEXEMES}/${length}/${page}`,
            ApiMethod.GET,
            ApiError.GET_LEXEMES,
            LexemePairCollectionParser.parse,
            ParseError.LEXEME_PAIRS
        );
    }

    static getLexemePairByIds = async (czId: string, enId: string): Promise<ResultLexemePair> => {
        const [result, error] = await Api.callApiForMysqlResponse<ILexemePair>(
            [ApiPath.LEXEME_PAIR, encodeURIComponent(czId), encodeURIComponent(enId)].join("/"),
            ApiMethod.GET,
            ApiError.GET_LEXEME_PAIR_BY_IDS,
            LexemePairParser.parse,
            ParseError.LEXEME_PAIR,
        );
        if (Array.isArray(result) && result.length === 0) {
            Logger.exception(new Error(`[Api.getLexemePairByIds]: - No data found for ${czId} ${enId}`));
            return [undefined, ApiError.INVALID_LEXEME_PAIR];
        }
        return error ? [undefined, error] : [result, undefined];
    }

    static getLexemePairsByText = async (inputText: string, inputLanguage: Language): Promise<ResultLexemePairs> => {
        return await Api.callApiForMysqlResponse<ILexemePair[]>(
            [ApiPath.LEXEME_PAIRS_BY_TEXT, encodeURIComponent(inputText), encodeURIComponent(inputLanguage)].join('/'),
            ApiMethod.GET,
            ApiError.GET_LEXEME_PAIRS_BY_TEXT,
            LexemePairCollectionParser.parse,
            ParseError.LEXEME_PAIRS
        );
    }

    static getLexemeBatchForTest = async (type: TestType, batchSize: number): Promise<ResultLexemePairs> => {
        return await Api.callApiForMysqlResponse<ILexemePair[]>(
            [ApiPath.LEXEMES_FOR_TEST, encodeURIComponent(type), encodeURIComponent(batchSize)].join('/'),
            ApiMethod.GET,
            ApiError.GET_LEXEMES_FOR_TEST,
            LexemePairCollectionParser.parse,
            ParseError.LEXEME_PAIRS,
        );
    }

    static getAllTranslations = async (sourceText: string, sourceLang: Language): Promise<Result<ILexeme[]>> => {
        return await Api.callApiForMysqlResponse<ILexeme[]>(
            [ApiPath.ALL_TRANSLATIONS, encodeURIComponent(sourceText), encodeURIComponent(sourceLang)].join('/'),
            ApiMethod.GET,
            ApiError.GET_ALL_TRANSLATIONS,
            LexemeCollectionParser.parse,
            ParseError.LEXEME_PAIRS,
        )
    }

    static deleteLexemePair = async (czId: number, enId: number): Promise<ResultString> => {
        return await Api.callApiForMysqlResponse<string>(
            [ApiPath.LEXEME_PAIR, encodeURIComponent(czId), encodeURIComponent(enId)].join("/"),
            ApiMethod.DELETE,
            ApiError.DELETE_LEXEME_PAIR,
            // TODO - provide a parser for the response
            (message: string) => [message, undefined],
            ParseError.DELETE_LEXEME_PAIR,
        );
    }

    static putLexemePairFamiliarity = async (czId: number, enId: number, familiarity: Familiarity): Promise<ResultVoid> => {
        return await Api.callApiForMysqlResponse<void>(
            [ApiPath.LEXEME_PAIR, encodeURIComponent(czId), encodeURIComponent(enId), encodeURIComponent(familiarity)].join("/"),
            ApiMethod.PUT,
            ApiError.PUT_LEXEME_PAIR_FAMILIARITY,
            // TODO - provide a parser for the response
            (data: any) => [void(0), undefined],
            ParseError.PUT_LEXEME_PAIR_FAMILIARITY,
        );
    }

    static postLexemePair = async (payload: LexemePairPayload): Promise<ResultJson> => {
        return await Api.callApiForMysqlResponse<ResultJson>(
            ApiPath.LEXEMES,
            ApiMethod.POST,
            ApiError.POST_LEXEME_PAIR,
            // TODO - provide a parser for the response
            (data: any) => [data, undefined],
            ParseError.POST_LEXEME_PAIR,
            payload
        );
    }

    static postTranslation = async (inputText: string, source: Language, target: Language): Promise<ResultString> => {
        const path = ApiPath.TRANSLATE;
        const method = ApiMethod.POST;
        const body = {
            q: encodeURIComponent(inputText),
            source,
            target,
            format: 'text'
        }
        const [result, error] = await LoaderUtil.request<DeeplTranslation>(path, method, body);
        if (error) {
            Logger.exception(new Error(`[Api.postTranslation]: ${error}`));
            return [undefined, ApiError.POST_TRANSLATION];
        }
        return [result.translations[0].translatedText, undefined];
    }

    static postUser = async (
        learningLanguage: Language,
        uiLanguage: Language,
    ): Promise<ResultVoid> => {
        const path = ApiPath.USER;
        const method = ApiMethod.POST;
        const body = {
            learningLanguage,
            uiLanguage
        }
        return await LoaderUtil.request<void>(path, method, body);
    }

    static postTestComplete = async (clientDate: string): Promise<Result<StreakData>> => {
        const path = ApiPath.TEST_COMPLETE;
        const method = ApiMethod.POST;
        const body = { clientDate };
        const [result, error] = await LoaderUtil.request<StreakData>(path, method, body);
        if (error) {
            Logger.exception(new Error(`[Api.postTestComplete]: ${error}`));
            return [undefined, ApiError.TEST_COMPLETE];
        }
        return [result, undefined];
    }
}
